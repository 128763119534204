import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import {
	setCleanPos,
	setMyPos,
	setPosDistributors,
	setPosLoading,
	setSelectedPos,
	setUpdateDistributor,
	setHasDistributor,
	managePosRegisterData,
} from './actions';
import type { PosParams, PosReducer } from './interfaces';
import { getStore } from 'stores/helpers';
import { findSelectedDistributors, pharmacyDistributorsCheck } from 'services/pos';
import { getBUs } from 'services/catalog';
import { HandleOpenDialogProps } from 'stores/dialog/interfaces';
import { Location, NavigateFunction } from 'react-router-dom';
import { handleSetCatalogMenuItems } from 'stores/catalog/middleware';
import { BusinessUnitResponse } from 'pages/pos/Distributors/interfaces';
import { handleNavigateToFidelityPage } from 'stores/fidelity/middleware';
import { distributorsMissingDialog, sellerViewErrorNotification } from './helpers';
import { insiderDispatcher } from 'services/insider';
import { notify } from 'front-commons/ds';
import { mainCustomerPage, simulatedViewType } from 'stores/customer/helpers';
import { CustomerAccess, CustomerRole } from 'stores/customer/interfaces';
import { HandleGetBasketDataParams } from 'stores/basket/interfaces';

export const handleSetMyPos = (myPos: PosParams[]): any => {
	return (dispatch: Dispatch) => {
		dispatch(setMyPos(myPos));
	};
};

export const handleClearSelectedPos = (): any => {
	return (dispatch: Dispatch) => {
		dispatch(setSelectedPos({} as PosReducer['selectedPos']));
	};
};

export const handleSetUpdateDistributor = (distributor: BusinessUnitResponse): any => {
	return (dispatch: Dispatch) => {
		dispatch(setUpdateDistributor(distributor));
	};
};

export const handleSetPosLoading = (loading: PosReducer['loading']): any => {
	return (dispatch: Dispatch) => {
		dispatch(setPosLoading(loading));
	};
};

export const handleSetPosDistributors = (distributors: Concrete<PosReducer['selectedPos']['distributors']>): any => {
	return (dispatch: Dispatch) => {
		dispatch(setPosDistributors(distributors));
	};
};

export const handleSetCleanPos = (): any => {
	return (dispatch: Dispatch) => {
		dispatch(setCleanPos());
	};
};

const getPharmacyById = (posId?: string) => {
	if (!posId) return { id: '' };

	const {
		pointOfSaleId: id,
		role,
		pointOfSaleCustomerId,
	} = getStore().posReducer.myPos.find((pos) => pos.pointOfSaleId === posId) || {
		pointOfSaleId: '',
	};

	return { id, role, pointOfSaleCustomerId };
};

export const handleSetHasDistributor = (hasDistributor: boolean): any => {
	return (dispatch: Dispatch) => {
		dispatch(setHasDistributor(hasDistributor));
	};
};

export const handleSetSelectedPos = (
	posId: string,
	handleFidelity: any,
	handleGetBasketData: (params: Pick<HandleGetBasketDataParams, 'callGTM' | 'loading' | 'initializeBefore' | 'posId'>) => void,
	handleOpenDialog: (dialog: HandleOpenDialogProps) => Promise<any>,
	navigate: NavigateFunction,
	location: Location,
	isDistributorsPage?: boolean,
	openSideBasket?: boolean,
): any => {
	return async (dispatch: Dispatch) => {
		dispatch(handleSetHasDistributor(false));
		const { role, pointOfSaleCustomerId } = getPharmacyById(posId);
		const id = posId;

		try {
			dispatch(setPosLoading(id));
			const { distributors: hasDistributors, businessUnitNoDistributors } = await pharmacyDistributorsCheck(id);

			const startedFrom = location.state?.startedFrom;

			if (hasDistributors) {
				const data = await getBUs(id);

				if (hasDistributors) {
					dispatch(handleSetHasDistributor(true));
					dispatch(handleFidelity({ posId: id, role }));
					handleGetBasketData({ loading: 'full', initializeBefore: true, posId: id });
					const distributors = await findSelectedDistributors({ posId: id });

					const posData = { id, role, businessUnities: data, distributors };

					await dispatch(setSelectedPos(posData));
					await dispatch(handleSetCatalogMenuItems(posData));

					if (pointOfSaleCustomerId) {
						insiderDispatcher.user({
							uuid: pointOfSaleCustomerId,
						});
					}

					if (startedFrom) {
						if (startedFrom === '/fidelidade') {
							dispatch(handleNavigateToFidelityPage(posId));
							navigate('/');
						} else {
							navigate(startedFrom, { state: null });
						}
					}

					if (isDistributorsPage && !!simulatedViewType() && !startedFrom) {
						navigate('/', { state: openSideBasket ? { openSideBasket: true } : undefined });
					}

					if (window.location.pathname.includes(mainCustomerPage()) && !startedFrom) {
						navigate('/', { state: openSideBasket ? { openSideBasket: true } : undefined });
					}

					if (window.location.pathname.includes('pedido-finalizado') && !startedFrom) {
						navigate('/', { replace: true });
					}
				}
				localStorage.setItem('searchModal', JSON.stringify(true))
				return null;
			}

			if (simulatedViewType()) {
				dispatch(handleSetHasDistributor(false));

				const data = await getBUs(id);

				const posData = { id, role, businessUnities: data };
				dispatch(handleSetCatalogMenuItems(posData));
				dispatch(setSelectedPos({ id, role, businessUnities: data }));

				if (pointOfSaleCustomerId) {
					insiderDispatcher.user({
						uuid: pointOfSaleCustomerId,
					});
				}
			}

			if (businessUnitNoDistributors?.length) {
				const isSimulatedBasic = [CustomerRole.CONSULTANT, CustomerAccess.BASIC].includes(
					String(simulatedViewType()) as any,
				);

				handleOpenDialog({
					...distributorsMissingDialog,
					footer: {
						...distributorsMissingDialog.footer,
						primaryButton: {
							label: isSimulatedBasic ? 'Entendi' : 'Ir para distribuidores',
							onClick: () =>
								navigate(isSimulatedBasic ? '/simular-farmacia' : `/minhas-farmacias/distribuidores?f=${posId}`, {
									state: isSimulatedBasic ? undefined : { buId: businessUnitNoDistributors[0] },
									replace: isSimulatedBasic,
								}),
						},
					},
				});

				return businessUnitNoDistributors;
			}

			return null;
		} catch (error) {

			if ((error as any)?.code === 403) {
				notify.negative({
					description: React.createElement(sellerViewErrorNotification),
				});

				return null;
			}

			if (isDistributorsPage) {
				if (simulatedViewType()) {
					notify.negative({
						description:
							' Esta farmácia está fora do painel comercial. Em caso de dúvida, entre em contato com a área de Efetividade.',
					});
				} else {
					notify.negative({
						description: React.createElement(sellerViewErrorNotification),
					});
				}
			} else {
				notify.negative({
					description:
						'Não foi possível executar essa ação. Por favor, entre em contato conosco para prosseguir com essa farmácia.',
				});
			}

			return null;
		} finally {
			dispatch(setPosLoading(false));
		}
	};
};

export const handleManagePosRegisterData = (action: 'SET' | 'CLEAR', data?: string): any => {
	return (dispatch: Dispatch) => {
		dispatch(managePosRegisterData(action, data));
	};
};
