import { CounterLoadingTypes } from 'front-commons/ds/components/Counter/interfaces';
import { CustomError } from 'shared/errors';
import { AddBasketItemsParams, GetProductInfoParams } from './interfaces';
import { simulatedViewType } from 'stores/customer/helpers';

export const getProductInfo = ({ productId, distributorId, buId, baskets }: GetProductInfoParams) => {
	const productBasket = baskets?.find(
		(basket) => basket.distributorId === distributorId && basket.businessUnitId === buId,
	);

	if (!productBasket) return undefined;

	const productInfo = productBasket.products.find((product) => product.id === productId);

	return productInfo;
};

export const manageAmountByMethod: Record<string, (props: { value?: AddBasketItemsParams['amount']; maxQuantity?: number | null; }) => number> = {
	add() {
		return 1;
	},
	increment({ value }) {
		return Number(value) + 1;
	},
	decrement({ value, maxQuantity }) {
		const prevValueNumber = Number(value);

		if (maxQuantity && prevValueNumber > maxQuantity) return maxQuantity;

		return prevValueNumber < 1 ? 0 : prevValueNumber - 1;
	},
};

export const getManageProductMessage = (method: CounterLoadingTypes) => {
	switch (method) {
		case 'decrement':
			return 'Unidade do produto removida';
		case 'increment':
			return 'Unidade do produto adicionada';
		case 'typing':
			return 'Quantidade do produto atualizada';
		default:
			return 'Produto adicionado ao carrinho';
	}
};

export const getNotificationMessage = (error: unknown) => {
	if (error === 'forbidden' && !!simulatedViewType()) {
		return 'Esse é um usuário simulado. Você não pode realizar essa ação.';
	}

	return error instanceof CustomError
		? error.message
		: 'Não foi possível atualizar a quantidade desse item, tente novamente.';
};
