import { getDataExport } from 'services/import';

export const getUploadErrorMessage = (error: unknown) => {
	const messages = {
		default: {
			feedback: 'Erro ao processar envio do arquivo',
			notification: 'Erro ao processar envio do arquivo',
		},
		not_acceptable: {
			feedback:
				'1) Se optou pela primeira linha como cabeçalho, as colunas devem ser texto. \n 2) Caso não optou por cabeçalho, o CNPJ deve ser somente números.',
			notification: 'Arquivo não aceito',
		},
	};

	return messages[error as keyof typeof messages] || messages.default;
};

export const getPercentageErrorMessage = (error: unknown) => {
	const messages = {
		default: 'Erro ao processar envio do arquivo',
		not_found: 'Importação de pedido não encontrado',
		bad_request: 'Não foi possível importar o arquivo',
	};

	return messages[error as keyof typeof messages] || messages.default;
};

export const downloadXls = async (order: string, name?: string) => {
	try {
		const response = await getDataExport(order);
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${name}.xls`);
		document.body.appendChild(link);
		link.click();
	} catch (error) {
		console.error(error);
	}
};

let state = true;

export const handleGetRecursion = () => state;
export const handleStartRecursion = () => {
	state = true;
};
export const handleStopRecursion = () => {
	state = false;
};

const getRecursion = {
	handleGetRecursion,
	handleStopRecursion,
	handleStartRecursion,
};

export default getRecursion;
